const modal = document.querySelector('.kcmm-modal');
const burgers = document.querySelectorAll('.kcmm-burger');
const search = document.querySelector('.kcmm-header__search');
const searchForm = document.querySelector('.kcmm-header__search-form');

if (burgers.length > 0) {
    burgers.forEach((burger) => {
        const label = burger.querySelector('.kcmm-burger__label');

        burger.addEventListener('click', () => {
            burger.classList.toggle('kcmm-burger--active');

            if (modal) modal.classList.toggle('kcmm-modal--visible');

            if (label) label.innerText = burger.classList.contains('kcmm-burger--active') ? label.dataset.close : label.dataset.default;
        });
    });
}

if (search && searchForm) {
    search.addEventListener('click', () => {
        search.classList.toggle('kcmm-header__search--active');
        searchForm.classList.toggle('kcmm-header__search-form--visible');
    });
}
